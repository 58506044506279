import { NFT_CONTRACT } from '../constants'
// import { convertStringToHex } from './bignumber'
import { formatPresaleMintTransaction, formatMintTransaction /*, sanitizeHex */} from './utilities'
// import * as list from "../../../src/controllers/providers";


export function getContract(chainId: number, web3: any) {
  const zevi = new web3.eth.Contract(
    NFT_CONTRACT[chainId].abi,
    NFT_CONTRACT[chainId].address
  )
  return zevi
}


export async function callMint(address: string, chainId: number, web3: any, mintQty: any, cost: number,  nftContract: any) {
  // console.log(address, mintQty, chainId, web3)
  const tx = await formatMintTransaction(address,chainId,mintQty,web3,cost, nftContract) 
  
  if(!tx){
    alert("Fatal error")
    return 
  }

  const txHash = await web3._provider.request({
    method: "eth_sendTransaction",
    params: [tx],
  });


  return txHash
  
}


export async function callPresaleMint(address: string, chainId: number, web3: any, mintQty: any, cost: number, presaleData: any, nftContract: any) {
  // console.log(address, mintQty, chainId, web3)
  const tx = await formatPresaleMintTransaction(address,chainId,mintQty,web3,cost, presaleData, nftContract) 
  
  if(!tx){
    alert("Fatal error")
    return 
  }

  const txHash = await web3._provider.request({
    method: "eth_sendTransaction",
    params: [tx],
  });


  return txHash
  
}


