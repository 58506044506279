import './Homepage.css'
import Mint from '../Mint/Mint'

import CollectionSlider from '../Sliders/CollectionSlider'

function Homepage() {


  return (
    <div className="App">
      

<div className='gap'> </div> 
<div className='mintHeader'>
<h1>Dorking Wanderers FC Digital Members Club</h1>
<h2>JOIN THE CLUB FOR ONLY £99</h2>
<h5>(Please note Ethereum network fees and price varies with the current price of Ethereum)</h5>
</div>
<div className="Desc ">
<CollectionSlider/>
<h3>
Welcome to our official Digital Members Club, a next generation fan experience for die-hard Wanderers fans fusing technology with gameday atmosphere.<br></br><br></br>
Beginning the 15th March while supply lasts, we will be releasing 270 Gold 'Special Edition' ultra-rare Wanderers Collectibles. These will be the only gold collectibles we issue in the collection. You may purchase and join the club on a first come first served basis.<br></br><br></br>
The Members Club gives fans access to a host of exclusive benefits and exciting rewards including:

  <ul>
    <li>A Limited Edition collectors shirt only available to Digital Club members</li>
    <li>Early access to Bunch of Amateurs 'Dorking Uncovered' including extended episodes</li>
    <li>2 tickets to a Mens first team home league fixture of your choice</li>
    <li>2 tickets to a Ladies first team home league fixture of your choice</li>
    <li>Access to The Full 90 for every league fixture</li>
    <li>Priority access to the 2023/24 playing kits</li>
    <li>A digital Wanderers collectible</li>
    <li>Giveaways incl. digital wearables</li>
  </ul>
</h3>
<h3>Ownership of a Wanderers collectible secures your lifelong membership and unlocks all the included perks, with many more to come. What's more, each piece of digital art is unique to the owners membership, your collectible is a one-of-one and is not owned by anyone else, anywhere in the world and verified by the blockchain!</h3>
  
  <h1 style={{textAlign: 'center'}}>COLLECT ONE NOW</h1>
  <div className='gap'> </div> 
{/* <h1>DORKING WANDERERS FC</h1>
<p>
Dorking Wanderers Football Club NFTs are a collection of 5,400 randomly generated PFP of the Club’s mascot with hand-drawn traits and variations.
<br/>
Each Dorking Wanderer FC cockerel is a unique NFT that lives on the Ethereum blockchain and provides unique perks to holders.
</p> */}
</div>
    <Mint/>
    <a style={{color: "white"}}href="https://opensea.io/collection/dorkingwanderers" target={"_blank"}><h1 style={{}}>View the collection</h1></a>

    </div>
  )
}


export default Homepage