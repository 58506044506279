import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import Homepage from "./components/HomepageComponent/Homepage";

// ReactDOM.render(<App />, document.getElementById("app"));
const root = createRoot(document.getElementById("app"));

root.render(<Homepage/>);

